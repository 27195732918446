import React from 'react';
import './SubscriptionFields.scss';

const SubscriptionFields = ({
  setContact,
  success,
  error
}) => {
  return (
    <div className="flex subscription-flex">
      { success 
        ?
          <div>{success}</div>
        : <>
            <input
              onChange={e => setContact(prevstate => ({...prevstate, first_name: e.target.value}))}
              type="text"
              placeholder="First name"
              required
            />

            <input
              onChange={e => setContact(prevstate => ({...prevstate, last_name: e.target.value}))}
              type="text"
              placeholder="Last name"
              required
            />

            <input
              onChange={e => setContact(prevstate => ({...prevstate, email: e.target.value}))}
              type="email"
              placeholder="Email address"
              required
            />
          </>
      }
      
      {
        error && <div className='error'>{error}</div>
      }
    
      {
        !success && 
        <input
          type="submit"
          className="submit-button"
          value="Subscribe"
        />
      }
    </div>
  );
};

export default SubscriptionFields;
