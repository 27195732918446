import React, { useEffect, useState, useRef } from 'react';
import SubscriptionFields from '../subscription_form/SubscriptionFields';
import close from '../../../../assets/images/news/close_icon.svg';
import axios from 'axios';

import './NewsletterPopup.scss';

const NewsletterPopup = ({ closeNewsletter }) => {
  let overlay = useRef(null);
  const formRef = useRef(null)

  const [contact, setContact] = useState({
    "email": "",
    "first_name": "",
    "last_name": ""
  })

  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      overlay.current.classList.remove('close');
    }, 5000);
  });

  const submit = e => {
    setError(false)
    e.preventDefault();
    formRef.current.reportValidity();

    axios.post('/newsletter', {
      contact
    }).then(res => {
      if (res.data.success) {
        setSuccess(res.data.success)
        setTimeout(() => {
          closeNewsletter();
        }, 5000);
      } else {
        setError(res.data.error)
      }
    })
    
  }

  return (
    <div
      className="newsletter-overlay close"
      id="newsletter-overlay"
      ref={overlay}
    >
      <div className="newsletter-title">Newsletter</div>
      <div className="newsletter-text">
        Subscribe to Rath Tutoring's newsletter for the latest updates in
        tutoring, test prep, admissions data, and trends in higher education:
      </div>
      <form onSubmit={submit} ref={formRef}>
        <SubscriptionFields
          setContact={setContact}
          error     ={error}
          success   ={success}
          />
      </form>
      <img
        src={close}
        className="close-newsletter"
        onClick={() => closeNewsletter()}
      />
    </div>
  );
};

export default NewsletterPopup;
